import moment from 'moment';

import type { TrelloWindow } from '@trello/window-types';

declare const window: TrelloWindow;

import { makeYearAwareCalendar } from './makeYearAwareCalendar';

moment.locale(window.locale, {
  months: [
    'janeiro',
    'fevereiro',
    'março',
    'abril',
    'maio',
    'junho',
    'julho',
    'agosto',
    'setembro',
    'outubro',
    'novembro',
    'dezembro',
  ],
  monthsShort: [
    'jan',
    'fev',
    'mar',
    'abr',
    'mai',
    'jun',
    'jul',
    'ago',
    'set',
    'out',
    'nov',
    'dez',
  ],
  weekdays: [
    'domingo',
    'segunda-feira',
    'terça-feira',
    'quarta-feira',
    'quinta-feira',
    'sexta-feira',
    'sábado',
  ],
  weekdaysShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
  weekdaysMin: ['dom', '2ª', '3ª', '4ª', '5ª', '6ª', 'sáb'],
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'LT:ss',
    L: 'DD/MM/YYYY',
    LL: 'D [de] MMMM [de] YYYY',
    LLL: 'D [de] MMMM [de] YYYY [às] LT',
    LLLL: 'D [de] MMMM',
  },
  // see `app/src/components/DateRangePicker/getLocalizedPlaceholder.ts`
  placeholder: {
    l: 'D/M/AAAA',
    LT: 'H:mm',
  },
  calendar: makeYearAwareCalendar({
    sameDay: '[Hoje às] LT',
    nextDay: '[Amanhã às] LT',
    lastDay: '[Ontem às] LT',
    nextWeek: 'llll [às] LT',
    lastWeek: 'llll [às] LT',
    sameYear: 'llll [às] LT',
    sameElse: 'll [às] LT',
  }),
  relativeTime: {
    future: 'em %s',
    past: '%s atrás',
    s: 'segundos',
    m: 'um minuto',
    mm: '%d minutos',
    h: 'uma hora',
    hh: '%d horas',
    d: 'um dia',
    dd: '%d dias',
    M: 'um mês',
    MM: '%d meses',
    y: 'um ano',
    yy: '%d anos',
  },
  ordinalParse: /\d{1,2}º/,
  ordinal: () => '%dº',
});
